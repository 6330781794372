<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Commission Deferred</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{name: 'Home'}">Home</router-link>
              </li>
              <li class="breadcrumb-item active">Commission Deferred</li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <section class="content">
      <div class="container-fluid">
        <div class="card" id="CommissionDeferred">
          <div class="overlay" v-if="is_loading">
            <i class="fas fa-2x fa-sync-alt fa-spin"></i>
          </div>
          <form @submit.prevent="handleSubmission()" method="POST" novalidate>
            <div class="card-header">
              <h3 class="card-title" v-if="!is_edit">Create</h3>
              <h3 class="card-title" v-if="is_edit">Edit</h3>
              <div class="card-tools">
                <router-link class="btn btn-info" :to="{name: 'CommissionDeferredList'}"><i class="fas fa-list"></i> List
                </router-link>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-8">
                  <div class="form-group ">
                    <label class="control-label">Client Reference-Id</label>
                    <input type="text" id="client_ref_id" name="client_ref_id" v-bind:class="{'has-error' : errors.has('client_ref_id')}" v-model="value.client_ref_id" class="form-control client_ref_id" placeholder="Client Reference-Id" readonly>
                    <div class="help text-danger" v-show="errors.has('client_ref_id')">
                      {{ errors.first("client_ref_id") }}
                    </div>
                  </div>
                  <div class="form-group required">
                    <label class="control-label">Account-Id</label>
                    <input type="text" id="account_id" name="account_id" v-bind:class="{'has-error' : errors.has('account_id')}" v-model="value.account_id" class="form-control account_id" placeholder="Account-Id">
                    <div class="help text-danger" v-show="errors.has('account_id')">
                      {{ errors.first("account_id") }}
                    </div>
                  </div>
                  <div class="form-group required">
                    <label class="control-label">Commission config</label>
                    <select id="commission_config_id" v-model="value.commission_config_id" v-bind:class="{'has-error' : errors.has('commission_config_id')}" class="form-control commission_config_id">
                      <option v-for="commissionConfig in allCommissionConfig" :value="commissionConfig.id" :key='commissionConfig.id'>{{ commissionConfig.name }}</option>
                    </select>
                    <div class="help text-danger" v-show="errors.has('commission_config_id')">
                      {{ errors.first("commission_config_id") }}
                    </div>
                  </div>
                  <div class="form-group required">
                    <label class="control-label">Amount</label>
                    <input type="text" id="amount" name="amount" v-bind:class="{'has-error' : errors.has('amount')}" v-model="value.amount" class="form-control amount" placeholder="Amount">
                    <div class="help text-danger" v-show="errors.has('amount')">
                      {{ errors.first("amount") }}
                    </div>
                  </div>
                  <div class="form-group required">
                    <label class="control-label">Commissioned amount</label>
                    <input type="text" id="commission_amount" name="commission_amount" v-bind:class="{'has-error' : errors.has('commission_amount')}" v-model="value.commission_amount" class="form-control commission_amount" placeholder="Commissioned amount">
                    <div class="help text-danger" v-show="errors.has('commission_amount')">
                      {{ errors.first("commission_amount") }}
                    </div>
                  </div>
                  <div class="form-group required">
                    <label class="control-label">Expected Disbursement Date</label>
                    <date-picker type="datetime" class="col-12" input-class="form-control" :input-attr="{name:'expected_disbursement_date'}" v-model="value.expected_disbursement_date" lang="en" format="YYYY-MM-DD hh:mm:ss" id="expected_disbursement_date" value-type="YYYY-MM-DD hh:mm:ss"></date-picker>
                    <div class="help text-danger" v-show="errors.has('expected_disbursement_date')">
                      {{ errors.first("expected_disbursement_date") }}
                    </div>
                  </div>
                  <div class="form-group required">
                    <label class="control-label">Can migrate</label>
                    <select class="form-control" style="width: 100%;" id="can_migrate" name="status" v-bind:class="{'has-error' : errors.has('can_migrate')}" v-model="value.can_migrate">
                      <option :value="1">Yes</option>
                      <option :value="0">No</option>
                    </select>
                    <div class="help text-danger" v-show="errors.has('can_migrate')">
                      {{ errors.first("can_migrate") }}
                    </div>
                  </div>
                  <div class="form-group required">
                    <label class="control-label">App-Id</label>
                    <select id="app_id" v-model="value.app_id" v-bind:class="{'has-error' : errors.has('app_id')}" class="form-control app_id">
                      <option v-for="application in allApplications" :value="application.app_id" :key='application.app_id'>{{ application.name }}</option>
                    </select>
                    <div class="help text-danger" v-show="errors.has('app_id')">
                      {{ errors.first("app_id") }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <button type="submit" v-if="!is_edit" class="btn btn-primary">Submit</button>
              <button type="submit" v-if="is_edit" class="btn btn-primary">Update</button>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>


<script>
import { mapGetters } from 'vuex'
import { CBSCommissionApi, CBSCommonApi } from '@/services/api'
import { uuid } from "vue-uuid";
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

export default {
  name: 'CommissionDeferredForm',
  components: {
    DatePicker
  },
  computed: {
    ...mapGetters(['profile'])
  },
  data: () => ({
    isSelectorLoading: false,
    is_loading: false,
    is_edit: false,
    params: {},
    value: {},
    allApplications: [],
    allCommissionConfig: [],
    pre: {}
  }),
  methods: {
    handleSubmission () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.is_loading = true;
          if (this.is_edit) {
            this.value.updated_by = this.profile.id
            CBSCommissionApi.updateDeferredCommission(this.params.id, this.value)
              .then((response) => {
                if (response.code == 200) {
                  this.$router.push({ name: 'CommissionDeferredList' })
                  this.$toaster.success(response.message)
                } else {
                  this.$toaster.error(response.message)
                }
              })
              .catch((error) => {
                this.$setErrorsFromResponse(error.response.data)
              }).finally(() => {
                this.is_loading = false
              })
          } else {
            this.value.organization_ref_id = this.profile.organization_ref_id
            this.value.user_ref_id = this.profile.user_ref_id
            this.value.role_ref_id = this.profile.role_ref_id
            this.value.created_by = this.profile.id
            CBSCommissionApi.storeDeferredCommission(this.value)
              .then((response) => {
                if (response.code == 200) {
                  this.$toaster.success(response.message);
                  this.$router.push({ name: 'CommissionDeferredList' });
                } else {
                  this.$toaster.error(response.message);
                }
              })
              .catch((error) => {
                this.$setErrorsFromResponse(error.response.data)
              }).finally(() => {
                this.is_loading = false
              })
          }
        }
      });
    },
    getDetail: function (id) {
      CBSCommissionApi.showDeferredCommission(id)
        .then((item) => {
          this.value = item
        })
        .catch((error) => {
          this.$toaster.error(error.response.data.message)
        })
        .finally(() => {
          this.is_loading = false
        })
    },
    getApplications: function () {
      CBSCommonApi.getAllApplications().then((response) => {
        this.allApplications = response;
      })
    },
    getCommissionConfig: function () {
      CBSCommonApi.getAllCommissionConfig().then((response) => {
        this.allCommissionConfig = response
      })
    },

    async initialize () {
      this.is_loading = true
      this.params = this.$route.params

      if (!_.isEmpty(this.params) && this.params.id !== undefined) {
        this.is_edit = true
      }
      if (this.is_edit) {
        await this.getDetail(this.params.id)
      } else {
        this.value.client_ref_id = uuid.v1();
      }
      await this.getApplications()
      await this.getCommissionConfig()
      this.is_loading = false

    }
  },
  mounted () {
    this.initialize()
  }
}
</script>
